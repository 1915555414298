export const environment = {
      //ubicacion: 'AIzaSyCCWUTqGTa4Re2HpYD5tVLe7rMu7-ag8Lk',///airview 1
      ubicacion: 'AIzaSyCMi3vLdP3Ploq61EQpPr8vAgEXcJu5NpQ',///airview 2
      production: true,
      firebase: {
        apiKey: "AIzaSyBFgSdV43wAN9rD-wK44nSkGwHuhoHzIjk",
        authDomain: "airview-a1375.firebaseapp.com",
        databaseURL: "https://airview-a1375.firebaseio.com",
        projectId: "airview-a1375",
        storageBucket: "airview-a1375.appspot.com",
        messagingSenderId: "1004949844260"
      }
    };